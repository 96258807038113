<template>
  <el-dialog
    :title="'Confirm Invoice ' + invoice.invoiceNumber"
    :visible.sync="isShow"
    width="80%"
  >
    <b-card no-body v-if="isAccrualEnabled()">
      <b-card-body>
        <b-row lg="12">
          <table class="table mb-2">
            <thead>
            <tr>
              <th>Accrual Number</th>
              <th>Charge Code</th>
              <th>Category</th>
              <th>Level 1</th>
              <th>Level 2</th>
              <th>Accrual Amount</th>
              <th>Actual Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="accrual in invoice.accruals">
              <td>{{ accrual.accrualNumber }}</td>
              <td>{{ accrual.chargeCode }}</td>
              <td>{{ accrual.chargeCodeCategory }}</td>
              <td>{{ accrual.chargeCodeLevel1 }}</td>
              <td>{{ accrual.chargeCodeLevel2 }}</td>
              <td>{{ accrual.totalAmountReceivable }} {{ accrual.currency }}</td>
              <td>{{ getAccrualActualAmount(accrual, invoice).toFixed(2) }} {{ accrual.currency }}</td>
            </tr>
            </tbody>
          </table>

          <div>
            Accrual Billing Period:
            <el-select v-model="invoice.accrualBillingPeriod">
              <el-option v-for="item in sunCurrencyProfileBillingPeriods" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </b-row>
      </b-card-body>
    </b-card>

    <div v-else>
       Accrual not enabled. Are you sure to confirm this invoice?
    </div>

    <div class="w-100 d-flex justify-content-end">
      <el-button type="primary" class="ml-1" @click="confirmInvoiceSubmit(invoice)">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isAccrualEnabled } from "@/views/erp/utils/system";
import axios from "axios";
import apis from "@/configs/apis";
import Invoice from "@/views/erp/mixins/Invoice";
import _ from "lodash";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";

export default {
  mixins: [Prompt, Invoice],
  data() {
    return {
      isShow: false,
      invoice: {},
      sunCurrencyProfileBillingPeriods: [],
    }
  },
  mounted() {
    this.loadSunCurrencyProfileItems();
  },
  methods: {
    isAccrualEnabled,
    confirmInvoice(invoice) {
      this.invoice = invoice;

      // if (!this.isChargeCodesValid(invoice)) {
      //  this.promptError('Invoice contains Charge Code that are not included in any Accruals');
      //  return;
      // }

      if (isAccrualEnabled()) {
        this.isShow = true;
      } else {
        this.$confirm('Confirm invoice?', 'Invoice: ' + invoice.invoiceNumber)
          .then(() => {
            return this.confirmInvoiceSubmit(invoice);
          })
          .catch(e => {
            if (e === 'cancel') return;
            this.promptError(e);
          });
      }
    },

    async confirmInvoiceSubmit(invoice) {
      return axios.post(apis.erpInvoices + '/' + invoice.id + '/confirm', invoice)
        .then(response => {
          this.isShow = false;
          this.promptInfo('Invoice Confirmed');
          EventBus.$emit("invoice-updated");
        })
        .catch(e => {
          console.log(e);
          this.promptError(e);
        }).finally(() => {
          this.isShowConfirmAccruals = false;
        });
    },

    loadSunCurrencyProfileItems() {
      axios.get(
        apis.erpGetMasterData + "/CURRENCY_PROFILE/SUN",
      ).then(response => {
        axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: response.value}})
          .then(response => {
            let billingPeriods = [];
            response.data.data.data.forEach(item => {
              billingPeriods.push(item.billingPeriod);
            });

            this.sunCurrencyProfileBillingPeriods = _.uniq(billingPeriods).sort().reverse();
          })
      });
    },
  }
}
</script>
