<template>
  <span>
    <el-tag size="mini" type="success" v-if="item.status === 'SENT'">{{ $t('Sent') }}</el-tag>
    <el-tag size="mini" type="info" v-else-if="item.status === 'PENDING'">{{ $t('Pending') }}</el-tag>
    <el-tag size="mini" type="primary" v-else-if="item.status === 'CONFIRMED'">{{ $t('Confirmed') }}</el-tag>
    <el-tag size="mini" type="primary" v-else-if="item.status === 'FA_CONFIRMED'">{{ $t('F&A Confirmed') }}</el-tag>
    <el-tag size="mini" type="primary" v-else-if="item.status === 'ACCRUAL_CONFIRMED'">{{ $t('Accrual Confirmed') }}</el-tag>
    <el-tag size="mini" type="danger" v-else-if="item.status === 'DELETED'">{{ $t('Deleted') }}</el-tag>
    <el-tag size="mini" type="primary" v-else>{{ item.status }}</el-tag>
  </span>
</template>

<script>
export default {
  props: {
    item: Object,
  }
}
</script>