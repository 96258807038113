<template>
  <el-dialog
    class="compact"
    :title="$t('Copy ' + getTypeForDisplay()) + ' ' + newInvoice.oldInvoiceNumber"
    :visible.sync="isShowCopyInvoice"
  >
    <b-row>
      <b-col lg="4">
        <b-form-group :label="$t('Account Code')">
          <el-input v-model="newInvoice.accountCode" size="mini" disabled="disabled"/>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Type')">
          <el-input v-model="newInvoice.type" size="mini" disabled="disabled"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-form-group :label="$t('Currency Profile')">
          <el-select filterable size="mini" v-model="newInvoice.currencyProfileId" style="width: 100%" @change="loadCurrencies()">
            <el-option v-for="item in currencyProfiles" :key="item.id" :label="item.name + (item.deleteTime ? ' (Deleted)' : '')" :value="item.id" :disabled="item.deleteTime !== null" v-if="item.deleteTime === null || item.id === profile.currencyProfileId">
              {{ item.name }}
            </el-option>
          </el-select>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Invoice Currency')">
          <el-select filterable size="mini" v-model="newInvoice.originalCurrency" style="width: 100%">
            <el-option v-for="item in currencies" :key="item" :label="item" :value="item">
              {{ item }}
            </el-option>
          </el-select>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (Month)')">
          <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="newInvoice.billingPeriodMonth" size="mini" @change="onBillingPeriodMonthChange()" style="width: 100%"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (From)')">
          <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="newInvoice.billingPeriodStart" size="mini" disabled="disabled" style="width: 100%"/>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (To)')">
          <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="newInvoice.billingPeriodEnd" size="mini" disabled="disabled" style="width: 100%"/>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group :label="$t('Billing Period (Sun)')">
          <el-input v-model="newInvoice.billingPeriod" size="mini" disabled="disabled"/>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" class="btn" @click="submitCopyInvoice()">
          {{ $t('Create') }}
        </b-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { isAccrualEnabled } from "@/views/erp/utils/system";
import axios from "axios";
import apis from "@/configs/apis";
import Invoice from "@/views/erp/mixins/Invoice";
import _ from "lodash";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";
import { BButton } from "bootstrap-vue";
import moment from "moment/moment";

export default {
  components: { BButton },
  mixins: [Prompt, Invoice],
  data() {
    return {
      isShowCopyInvoice: false,
      profile: {},
      newInvoice: {},
      currencies: [],
      currencyProfiles: [],
    }
  },
  mounted() {
    this.loadCurrencyProfiles();
    this.loadCurrencies();
  },
  methods: {
    loadCurrencyProfiles() {
      axios.get(apis.erpCurrencyProfiles)
        .then(response => {
          this.currencyProfiles = response.data.data.data;
        })
    },

    loadCurrencies() {
      axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: this.newInvoice.currencyProfileId}})
        .then(response => {
          let currencies = [];
          response.data.data.data.forEach(item => {
            currencies.push(item.quoteCurrency);
          });

          if (this.profile.paymentCurrency) currencies.push(this.profile.paymentCurrency);
          this.currencies = _.uniq(currencies).sort();
        })
    },

    onBillingPeriodMonthChange() {
      this.newInvoice.billingPeriod = this.newInvoice.billingPeriodMonth.substring(0, 7).replace("-", "/0");
      this.newInvoice.billingPeriodStart = moment(this.newInvoice.billingPeriodMonth).startOf('month').format('YYYY-MM-DD');
      this.newInvoice.billingPeriodEnd = moment(this.newInvoice.billingPeriodMonth).endOf('month').format('YYYY-MM-DD');
    },

    copyInvoice(item, profile) {
      this.profile = profile;

      this.newInvoice = {
        id: item.id,
        type: item.type,
        isCopyInvoice: true,
        currencyProfileId: item.currencyProfileId,
        originalCurrency: profile.paymentCurrency,
        accountCode: item.accountCode,
        oldInvoiceNumber: item.invoiceNumber,
        billingPeriodMonth: item.billingPeriodStart,
        billingPeriod: item.billingPeriod,
        billingPeriodStart: item.billingPeriodStart,
        billingPeriodEnd: item.billingPeriodEnd,
      }

      this.isShowCopyInvoice = true;
    },

    submitCopyInvoice() {
      axios.post(apis.erpInvoices + '/create/copy', this.newInvoice)
        .then(result => {
          this.isShowCopyInvoice = false;
          this.editInvoice(result.data.data);
          EventBus.$emit("invoice-created");
        }).catch((e) => {
        this.promptError(e);
      });
    },
  }
}
</script>