import axios from "axios";
import apis from "@/configs/apis";

export default {
  methods: {
    downloadInvoice(item) {
      this.isLoading = true;
      axios.post(apis.erpInvoiceDownload.substring(1), { invoiceNumber: item.invoiceNumber }, {
        responseType: 'blob'
      })
      .then((response) => {
        //window.open(URL.createObjectURL(response.data));
        this._responseToFile(response, item.invoiceNumber + '.pdf');
      })
      .catch(async e => {
        this.promptError(await this._getErrorMessage(e));
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

    downloadFile(type, serverFilename, clientFilename) {
      this.isLoading = true;
      axios.get(apis.erpFileDownload.substring(1) + "/" + type + "/" + serverFilename, {
        responseType: 'blob'
      })
      .then((response) => {
        this._responseToFile(response, clientFilename);
      })
      .catch(async e => {
        this.promptError(await this._getErrorMessage(e));
      })
      .finally(() => {
        this.isLoading = false;
      })
    },

    async _getErrorMessage(e) {
      try {
        if (e.request.responseType === 'blob' && e.response.data instanceof Blob && e.response.data.type && e.response.data.type.toLowerCase().indexOf('json') !== -1) {
          return JSON.parse(await e.response.data.text()).message;
        }

        return e.message;
      } catch (e) {
        return e.message;
      }
    },

    _responseToFile(response, clientFilename) {
      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.style.display = 'none';

      const url = window.URL.createObjectURL(response.data);

      anchorElement.href = url;
      anchorElement.download = clientFilename;
      anchorElement.click();

      window.URL.revokeObjectURL(url);
    }
  },
}