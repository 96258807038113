export const getInvoiceIconStyle = function(type, invoice) {
  if (!invoice.invoiceStatus) return {color: 'grey'};

  switch (type) {
    case 'CBO':
      if (invoice.invoiceStatus.isChargeCodeCompletedCBO) return {color: 'green'}
      if (invoice.invoiceStatus.isChargeCodeRequireCBO && !invoice.invoiceStatus.isChargeCodeCompletedCBO) return {color: 'red'}
      if (!invoice.invoiceStatus.isChargeCodeRequireCBO && !invoice.invoiceStatus.isChargeCodeCompletedCBO) return {color: 'grey'}
    case 'Fulfillment':
      if (invoice.invoiceStatus.isChargeCodeCompletedFulfillment) return {color: 'green'}
      if (invoice.invoiceStatus.isChargeCodeRequireFulfillment && !invoice.invoiceStatus.isChargeCodeCompletedFulfillment) return {color: 'red'}
      if (!invoice.invoiceStatus.isChargeCodeRequireFulfillment && !invoice.invoiceStatus.isChargeCodeCompletedFulfillment) return {color: 'grey'}
    case 'Others':
      if (invoice.invoiceStatus.isChargeCodeCompletedOthers) return {color: 'green'}
      if (invoice.invoiceStatus.isChargeCodeRequireOthers && !invoice.invoiceStatus.isChargeCodeCompletedOthers) return {color: 'red'}
      if (!invoice.invoiceStatus.isChargeCodeRequireOthers && !invoice.invoiceStatus.isChargeCodeCompletedOthers) return {color: 'grey'}
  }
}
