<template>
  <el-dialog
    :title="'Confirm Sun Close Invoice ' + invoice.invoiceNumber"
    :visible.sync="isShow"
    width="80%"
  >
    <div class="w-100 d-flex justify-content-end">
      <el-button type="primary" class="ml-1" @click="confirmSunCloseSubmit(invoice)">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isAccrualEnabled } from "@/views/erp/utils/system";
import axios from "axios";
import apis from "@/configs/apis";
import Invoice from "@/views/erp/mixins/Invoice";
import _ from "lodash";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";

export default {
  mixins: [Prompt, Invoice],
  data() {
    return {
      isShow: false,
      invoice: {},
    }
  },
  mounted() {
    this.loadSunCurrencyProfileItems();
  },
  methods: {
    isAccrualEnabled,
    closeSunInvoice(invoice) {
      this.invoice = invoice;

      this.$confirm('Confirm Sun Close invoice?', 'Invoice: ' + invoice.invoiceNumber)
        .then(() => {
          return this.confirmSunCloseSubmit(invoice);
        })
        .catch(e => {
          if (e === 'cancel') return;
          this.promptError(e);
        });
    },

    async confirmSunCloseSubmit(invoice) {
      return axios.post(apis.erpInvoices + '/' + invoice.id + '/closeSun', invoice)
        .then(response => {
          this.isShow = false;
          this.promptInfo('Invoice Sun Closed');
          EventBus.$emit("invoice-updated");
        })
        .catch(e => {
          console.log(e);
          this.promptError(e);
        }).finally(() => {
          this.isShowConfirmAccruals = false;
        });
    },
  }
}
</script>