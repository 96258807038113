export const MAX_PAGE_SIZE = 0x7fffffff;

export const isDevelopmentSite = () => {
  return process.env.NODE_ENV === 'development';
}

export const isAccrualEnabled = () => {
  return true;
}

export const PROFILE_STATUSES = {'DOCUMENTED': "Documented", 'VERIFIED': "Verified", 'PENDING': "Pending", 'SUSPENDED': 'Suspended', 'BLACKLISTED': 'Blacklisted', 'INACTIVE': 'Inactive'}