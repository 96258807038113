export const showAction = (action) => {
  if (action === null) return null;
  return action.toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase());
}

export const toFixed = (number, digits) => {
  if (number === null) return (0).toFixed(digits);
  return number.toFixed(digits);
}

