<template>

</template>

<script>
import axios from "axios";
import apis from "@/configs/apis";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";

export default {
  mixins: [Prompt],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    reviseInvoice(invoice) {
      this.$confirm('Revise invoice? \nNew invoice number suffix will be added.', 'Invoice: ' + invoice.invoiceNumber)
        .then(() => {
          return axios.post(apis.erpInvoices + '/' + invoice.id + '/revise')
        })
        .then(response => {
          this.promptInfo('Invoice is now open for revise');
          EventBus.$emit("invoice-updated");
        })
        .catch(e => {
          if (e === 'cancel') return;
          this.promptError(e);
        });
    },
  }
}
</script>