import { EventBus } from "@/views/erp/utils/event-bus";

export default {
  methods: {
    getTypeForDisplay() {
      return this.type === 'CREDIT_NOTE' ? 'Credit Note' : 'Invoice';
    },

    isChargeCodesValid(invoice) {
      let valid = true;

      invoice.items.forEach(item => {
        if (!this.isChargeCodeValid(item.warehouseCode, invoice)) {
          valid = false;
        }
      });

      return valid;
    },

    isChargeCodeValid(warehouseCode, invoice) {
      if (invoice.accruals.length === 0) return true;

      let valid = false;

      invoice.accruals.forEach(accrual => {
        if (accrual.warehouseCode === warehouseCode) valid = true;
      });

      return valid;
    },

    getAccrualActualAmount(accrual, invoice) {
      let total = 0;

      invoice.items.forEach(item => {
        if (item.warehouseCode === accrual.warehouseCode) total += parseFloat(item.amount);
      });

      return total;
    },

    viewInvoice(item) {
      if (item.status === 'DELETED') return;
      this.$router.push({
        name: 'erp-invoice-view',
        params: { company_id: item.organizationProfileId, id: item.id }
      })
    },

    editInvoice(item) {
      if (item.status === 'DELETED') return;
      this.$router.push({
        name: 'erp-invoice-edit',
        params: { company_id: item.organizationProfileId, id: item.id }
      })
    },
  }
}
