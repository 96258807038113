<template>
  <el-dialog
    :visible.sync="isShow"
    :title="title"
    width="30%"
  >
    <div class="text-center">
      <el-upload
        ref="upload"
        :action="axiosBaseUrl() + apis.erpFileUpload.substring(1) + '/' + type"
        :headers="{'Authorization': getAccessToken()}"
        :multiple="false"
        :limit="1"
        :auto-upload="false"
        :before-upload="handleBeforeUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
        drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        <div class="el-upload__tip">
          <slot name="tip">
            Only accepts files with size less than {{ getMaxSizeForDisplay() }}
          </slot>
        </div>
      </el-upload>

      <div slot="footer" class="dialog-footer mt-2 text-right">
        <b-button variant="primary" class="btn" @click="upload();">
          {{ $t('Upload') }}
        </b-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { axiosBaseUrl } from "@/libs/axios";
import apis from "@/configs/apis";
import { getAccessToken } from "@/utils/account-localstorage";
import Prompt from "@/views/erp/mixins/Prompt";

export default {
  mixins: [Prompt],
  computed: {
    apis() {
      return apis
    }
  },
  data() {
    return {
      title: '',
      type: '',
      config: {maxSize: 10485760, allowedExtension: null},
      callback: null,
      isShow: false,
    }
  },
  methods: {
    axiosBaseUrl() {
      return axiosBaseUrl
    },
    getAccessToken,

    showUpload(title, type, config = {maxSize: 10485760}, callback = null) {
      this.title = title;
      this.type = type;
      this.config = config;
      this.callback = callback;
      this.isShow = true;
    },

    hideUpload() {
      this.isShow = false;
      this.reset();
    },

    upload() {
      this.$refs.upload.submit();
    },

    reset() {
      this.$refs.upload.clearFiles();
    },

    getMaxSizeForDisplay() {
      return (this.config.maxSize / 1048576).toFixed(0) + ' MB';
    },

    handleBeforeUpload(file) {
      if (file.size > this.config.maxSize) {
        this.promptError("File '"+ file.name + "' size is too large.")
        return false
      }

      if (this.config.allowedExtension) {
        const idxDot = file.name.lastIndexOf('.') + 1;
        const extFile = file.name.substring(idxDot, file.name.length).toLowerCase();

        if (extFile !== this.config.allowedExtension) {
          this.promptError("File '"+ file.name + "' is not valid.")
          return false
        }
      }
    },

    async handleSuccess(response, file, fileList) {
      if (typeof this.callback === 'function') this.callback({
        clientFilename: file.name,
        serverFilename: response.serverFilename
      });

      this.reset();
    },

    async handleError(error, file, fileList) {
      this.promptError('File upload error: ' + error);
      this.reset();
    },
  }
}
</script>