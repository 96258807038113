import moment from "moment-timezone";

export const DEFAULT_FORMAT_LONG = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_FORMAT_LONG_WITH_TIMEZONE = "YYYY-MM-DD HH:mm:ss Z";
export const formatWithCurrentTimezone = (value) => {
  if (value && value.length === 20) {
    return moment(value).format(DEFAULT_FORMAT_LONG) + ' ' + getCurrentTimezoneName();
  }

  return value ? value + ' UTC' : '';
}

export const getCurrentTimezone = () => {
  return moment().format('Z');
}

export const getCurrentTimezoneName = () => {
  return moment.tz(moment.tz.guess()).zoneAbbr()
}

export const utcTimeToLocal = (value) => {
 return moment(value).format(DEFAULT_FORMAT_LONG);
}

export const isTimePassed = (value) => {
  return moment().isAfter(value);
}

export const startOfMonthDate = (value) => {
  return moment(value).startOf('month').format('YYYY-MM-DD');
}

export const endOfMonthDate = (value) => {
  return moment(value).endOf('month').format('YYYY-MM-DD');
}
